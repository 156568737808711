<template>
  <b-container fluid>
    <b-row>
      <b-col v-for="item in appStatistics" :key="item.title" lg="3" md="6">
        <iq-card class="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:body>
            <h5>{{ item.title }}</h5>
            <div class="d-flex justify-content-between align-items-center">
              <div class="value-box">
                <h3 class="mb-0">
                  {{ item.number }}
                </h3>
              </div>
              <div class="iq-iconbox iq-bg-primary">
                <i :class="item.icon" />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
// import AmChart from '@/components/core/charts/AmChart'
import Counter from "@/components/core/counter/Counter";
import dashboardServices from "./services/dashboard";

export default {
  components: { Counter },
  data() {
    return {
      appStatistics: [
        { title: "Admins", number: 0, icon: "fas fa-user" },
        { title: "Clients", number: 0, icon: "fas fa-user-tag" },
        { title: "Predictions", number: 0, icon: "fas fa-chart-bar" },
        { title: "Horses", number: 0, icon: "fas fa-horse-head" },
        { title: "Participants", number: 0, icon: "fas fa-users" },
        { title: "Sponsors", number: 0, icon: "fas fa-dollar-sign" },
        { title: "Owners", number: 0, icon: "fas fa-user-tie" },
        { title: "Trainers", number: 0, icon: "fas fa-hat-cowboy" },
        { title: "Jockeys", number: 0, icon: "fas fa-hat-cowboy-side" },
        { title: "Tracks", number: 0, icon: "fas fa-thumbtack" },
        { title: "Competitions", number: 0, icon: "fas fa-trophy" },
        { title: "Races", number: 0, icon: "fas fa-user" },
      ],
    };
  },
  methods: {
    async findStatistics() {
      this.loading = true;
      const { data } = await dashboardServices.find();
      this.appStatistics.forEach((item) => {
        item.number = data[item.title];
      });
      this.loading = false;
    },
  },
  mounted() {
    core.index();
  },
  created() {
    this.findStatistics();
  },
};
</script>
